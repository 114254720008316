

import React from "react";
import '../../components/Layout/layout.css';


const CustomImage = ({ children }) => {
  

  return (
    <div className="blog-hero-img">
      {children}
    </div>
  );
};

export default CustomImage;
